const initialState = {
  allJobs: null,
  addSpecialization: null,
  list: [],
  edit: [],
  specializations: [],
  jobDetails: null,
  careerDetails:null,
  applyJob: null,
};

const JobReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_JOBS":
      return { ...state, allJobs: action.data };
    case "GET_ALL_JOBS":
      return { ...state, list: action.data };
    case "EDIT_JOB":
      return { ...state, edit: action.data };
    case "GET_ALL_SPECIALIZATIONS":
      return { ...state, specializations: action.data };
    case "ADD_SPECIALIZATION":
      return { ...state, addSpecialization: action.data };
    case "GET_JOB_DETAILS":
      return { ...state, jobDetails: action.data };
    case "GET_CAREER_DETAILS":
      return { ...state, careerDetails: action.data };
    case "APPLY_JOB":
      return { ...state, applyJob: action.data };
    // case 'UPDATE_JOBS':
    //     return { ...state, details: action.data };
    default:
      return state;
  }
};
export default JobReducer;
