const initialState = {
  allDatas: null,
  list: [],
  edit: [],
  hireDetails: null,
  viewDetails:null,
};

const HireReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_HIRE":
      return { ...state, allDatas: action.data };
    case "GET_ALL_HIRES":
      return { ...state, list: action.data };
    case "EDIT_HIRE":
      return { ...state, edit: action.data };
    case "GET_HIRE_DETAILS":
      return { ...state, hireDetails: action.data };
    case "VIEW_HIRE_DETAILS":
      return { ...state, viewDetails: action.data };
    default:
      return state;
  }
};
export default HireReducer;
