import { combineReducers } from 'redux'
import  PostReducer  from "./PostReducer"
import  ProductReducer  from "./ProductReducer"
import  JobReducer  from "./JobReducer"
import  HireReducer  from "./HireReducer"
import  users  from "../../../src/views/user/store/reducer/index.js"

// Combine Reducers
const RootReducer = combineReducers({
    posts: PostReducer,
    users: users,
    products:ProductReducer,
    jobs: JobReducer,
    hires: HireReducer

});

export default RootReducer;